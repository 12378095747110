import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTheme } from '../../theme'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: 18px;
// ?? da li
  // overflow-x: hidden;
  `

export const Main = ({ children }) => {
  const theme = useTheme()
  return (
    <Wrapper {...theme}>
      {children}
    </Wrapper>
  )
}

Main.propTypes = {
  children: PropTypes.node
}
