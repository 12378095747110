import React from 'react'
import styled from 'styled-components'
import { BlackText } from '../blackText/BlackText'
import { RedText } from '../redText/RedText'
import { Text, TextInline } from '../text/Text'
import { Satoshi } from '../satoshi/Satoshi'
import { BlackLightText } from '../blackText/BlackLightText'
import { useDevice } from '../../context/device/DeviceContextProvider'

const ResurrectionStyle = styled.section`
  background-color: #E4E0E0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  padding-bottom: ${props => props.isMobile ? '0px' : '147px'};
  margin-top: 130px;
  width: 100vw;
`

const Header = styled.div`
  text-align: center;
  width: ${props => props.isMobile && '80vw'};
  margin-bottom: ${props => props.isMobile && '10vw'};
`

const Paragraph = styled.div`
  text-align: center;
  width: ${props => props.isMobile ? '80vw' : '49%'};
`
const HellAndHeavenSection = styled.div`
  margin-top: 100px;
  height: ${_ => _.isMobile ? '100px' : '256px'};
  & > img {
    width: ${_ => _.isMobile ? '348px' : '890px'};
    height: ${_ => _.isMobile ? '100px' : '256px'};
  }
`

const SatoshisContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile && 'column'};
  justify-content: center;
  width: 90vw;
  margin-top: 68px;
`

const SatoshiItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${_ => _.isMobile ? '0' : '33px'};
`

const SatoshiLabel = styled.div`
  margin: ${props => props.isMobile ? '20px 0 75px 0' : '20px'};
`

export const Resurrection = () => {
  const { isMobile } = useDevice()
  const satoshiSize = isMobile ? '250px' : '364px'
  return (
    <ResurrectionStyle isMobile={isMobile}>
      <Header isMobile={isMobile}>
        <div><BlackText fontSize={isMobile ? '50px' : ''} lineHeight={isMobile ? '50px' : ''}>The world’s first</BlackText></div>
        <div>
          <RedText isBlack={true} size={isMobile ? '50px' : '8.125rem'} lineHeight={isMobile ? '50px' : '7.5rem'}>Resurrection</RedText>
          <BlackText fontSize={isMobile ? '50px' : ''} lineHeight={isMobile ? '50px' : ''}> NFT</BlackText>
        </div>
      </Header>
      <Paragraph isMobile={isMobile}>
        <Text textAlign={'center'} fontSize={isMobile ? '18px' : ''} isBold={true} lineHeight={isMobile ? '22px' : ''}>
          Much more than a floor raising plan. Turn your common, rare, super-rare, and One Of A Kind NFTs into Epic and Legendary rarity classes!
        </Text>
      </Paragraph>
      <Paragraph isMobile={isMobile}>
        <Text textAlign={'center'} fontSize={isMobile ? '18px' : ''} isBold={true} lineHeight={isMobile ? '22px' : ''}>
          A Satoshi Hunt (buyback from OpenSea) targets all NFTs from our collection, not the cheapest ones. Hunted Satoshis then go to Satoshi Hell. Use our utility tokens to resurrect them and buy them back!
        </Text>
      </Paragraph>
      <Paragraph isMobile={isMobile}>
        <Text textAlign={'center'} fontSize={isMobile ? '18px' : ''} isBold={true} lineHeight={isMobile ? '22px' : ''}>
          Resurrecting an original (genesis) Satoshi turns it into a Hell version (Epic rarity class). Resurrecting a Hell version turns it into a Heaven version (Legendary rarity class).
        </Text>
      </Paragraph>
      <HellAndHeavenSection isMobile={isMobile}>
        <img src={`/img/sprites/GoToHell${isMobile ? '-Mobile' : ''}.png`}/>
      </HellAndHeavenSection>
      <SatoshisContainer isMobile={isMobile}>
        <SatoshiItem isMobile={isMobile}>
          <Satoshi name={'ResurrectionGenesis'} size={satoshiSize} rounded/>
          <SatoshiLabel isMobile={isMobile}>
            <TextInline isBlack={true}>Step 1</TextInline>
            &nbsp;&nbsp;
            <BlackLightText>Genesis</BlackLightText>
          </SatoshiLabel>
        </SatoshiItem >
        <SatoshiItem isMobile={isMobile}>
          <Satoshi name={'ResurrectionHell'} size={satoshiSize} rounded/>
          <SatoshiLabel isMobile={isMobile}>
            <TextInline isBlack={true}>Step 2</TextInline>
            &nbsp;&nbsp;
            <BlackLightText>Hell Version (Epic)</BlackLightText>
          </SatoshiLabel>
        </SatoshiItem>
        <SatoshiItem style={{ marginRight: 0 }}>
          <Satoshi name={'ResurrectionHeaven'} size={satoshiSize} rounded/>
          <SatoshiLabel isMobile={isMobile}>
            <TextInline isBlack={true}>Step 3</TextInline>
            &nbsp;&nbsp;
            <BlackLightText>Heaven Version (Legendary)</BlackLightText>
          </SatoshiLabel>
        </SatoshiItem>
      </SatoshisContainer>
    </ResurrectionStyle>
  )
}
