import { createTheming } from '@callstack/react-theme-provider'

export const theme = {
  default: {
    primaryColor: '#FFFFFF',
    accentColor: '#FF1F1F',
    backgroundColor: '#000000',
    textColor: '#86868B',
    secondaryColor: '#E4E0E0'
  }
}

const { ThemeProvider, withTheme, useTheme } = createTheming(theme.default)

export { ThemeProvider, withTheme, useTheme }
