import React from 'react'
import styled from 'styled-components'
import { RedTitleParagraph } from '../redTitleParagraph/RedTitleParagraph'
// import { Sidekick } from '../sidekick/Sidekick'
import { VideoEmbed } from '../videoEmbed/VideoEmbed'
import { useDevice } from '../../context/device/DeviceContextProvider'
import { TextInline } from '../text/Text'

const ForOwnersStyle = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-inline: 11vw;
`

const OwnerRow = styled.div`
  width: ${_ => _.width || '100%'};
  display: flex;
  align-items: ${_ => _.align || 'center'};
  //margin-top: ${_ => _.noMargin ? '165px' : '330px'};
  margin-top: ${_ => _.isMobile ? '150px' : '250px'};
  
  &.columnDirection {
    flex-direction: column;
  };
`

const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${_ => _.justify ? _.justify : _ => _.right ? 'flex-end' : 'flex-start'};
  padding-left: ${_ => _.right && !_.justify ? '80px' : ''};
  padding-right: ${_ => !_.right && !_.justify ? '80px' : ''};

  &.satoshisWorld > img {
    //padding-top: 35px;
    width: 80%;
  };
`

export const ForOwners = () => {
  const { isMobile } = useDevice()
  return (
    <ForOwnersStyle>
      <OwnerRow width={isMobile ? '80vw' : '55%'} isMobile={isMobile}>
        <RedTitleParagraph
          isBlack={true}
          title={'Utility token'}
          text={text.utility}
          textAlign={'center'}/>
      </OwnerRow>
      <img style={{ marginTop: '50px' }} src={'/img/logos/WasToken.svg'}/>
      <OwnerRow className={isMobile && 'columnDirection'} width={isMobile ? '80vw' : ''} isMobile={isMobile}>
        <Column right={isMobile} justify={isMobile}>
          <RedTitleParagraph
            title={'Merchandise for early adopters'}
            text={text.merchandise}
            textAlign={isMobile ? 'center' : ''}
          />
        </Column>
        <Column right={!isMobile} left={isMobile} justify={isMobile}>
          <img src={'/img/logos/Hoodie.jpg'}/>
        </Column>
      </OwnerRow>
      <OwnerRow className={isMobile && 'columnDirection'} width={isMobile ? '80vw' : ''} isMobile={isMobile}>
        <Column right={isMobile} justify={isMobile}>
          <RedTitleParagraph
            title={'Arch-enemy & Sidekick NFT drops'}
            text={text.archEnemy}
            textAlign={isMobile ? 'center' : ''}
            />
        </Column>
        <Column right={!isMobile} left={isMobile} justify={isMobile}>
          <img src={'/img/logos/SidekickVillain.jpg'}/>
        </Column>
      </OwnerRow>
      <OwnerRow className={isMobile && 'columnDirection'} width={isMobile ? '80vw' : ''} isMobile={isMobile}>
        <Column right={isMobile} justify={isMobile}>
          <RedTitleParagraph
            title={'Free stuff'}
            text={text.freeStuff}
            textAlign={isMobile ? 'center' : ''}
            />
        </Column>
        <Column right={!isMobile} left={isMobile} justify={isMobile}>
          <VideoEmbed width={isMobile ? '75vw' : '445px'} src={'https://www.youtube.com/embed/TCq7kk47eSg'}/>
        </Column>
      </OwnerRow>
      <OwnerRow className={isMobile && 'columnDirection'} width={isMobile ? '80vw' : ''} isMobile={isMobile}>
        <Column right={isMobile} justify={isMobile}>
          <RedTitleParagraph
            title={'PFP Maker'}
            text={text.pfpMaker}
            textAlign={isMobile ? 'center' : ''}
          />
        </Column>
        <Column right={!isMobile} left={isMobile} justify={isMobile}>
          <VideoEmbed width={isMobile ? '75vw' : '445px'} src={'https://www.youtube.com/embed/gB1B1VCwwsw'}/>
        </Column>
      </OwnerRow>
      <OwnerRow className={isMobile && 'columnDirection'} width={isMobile ? '80vw' : ''} isMobile={isMobile}>
        <Column right={isMobile} justify={isMobile}>
          <RedTitleParagraph
            title={'Giving back to the world'}
            text={text.givingBack}
            textAlign={isMobile ? 'center' : ''}
          />
        </Column>
        <Column className={isMobile && 'satoshisWorld'} justify={isMobile ? 'center' : 'flex-end'}>
          <img src={'/img/logos/SatoshisGlobe.svg'}/>
        </Column>
      </OwnerRow>

    </ForOwnersStyle>
  )
}

const text = {
  utility: [
    'Owners of the We Are Satoshis NFT will be awarded utility tokens for each Satoshi they own. This utility token will be airdropped to the NFT owners at first. At later stages, utility tokens will be awarded to the NFT owners on a regular, timely basis, where each Satoshis NFT will get the same amount of tokens each month.'
  ],
  merchandise: [
    'Everybody that participates in the pre-sale and public mint events will receive a limited edition We Are Satoshis style hoodie and a high-quality poster to decorate your living space.'
  ],
  archEnemy: [
    'What is a superhero without a villain, an arch-enemy? And how in the world can he defeat the evil without help from a faithful companion? :)', 'Q2 & Q3 2022 will bring two new NFTs for each Satoshi you own. Both Villain and Companion NFTs will be a series of 1024 UNIQUE NFTs, and both will be free for the owners of the We Are Satoshis NFT.'
  ],
  freeStuff: [
    <>For everyone in the community, even if you did not have a chance to get your hands on one of the We Are Satoshis NFTs, we have prepared a set of cool wallpapers for all your screens, from smartwatches and phones to tablets and desktops. You can get them <a href={'/Wallpapers-full.zip'} target='_blank' rel="noreferrer"><TextInline color={'#FF1F1F'}>here.</TextInline></a></>
  ],
  pfpMaker: [
    'A PFP maker for all NFT owners will make it easy to crop and download your Satoshi PFPs!'
  ],
  givingBack: [
    'Each owner of the We Are Satoshis NFT can participate in our charitable activities by proposing the cause and voting for other proposals submitted by other community members. At first, a predefined number of philanthropic activities will be carried out with a budget allocated to it from the initial mint revenue.'
  ]
}
