import styled from 'styled-components'
import { withTheme } from '../../theme'

export const WhiteText = withTheme(styled.div`
  color: ${_ => _.theme.primaryColor};
  font-size: 1.875rem;
  text-align: ${_ => _.textAlign || 'left'};
  font-family: 'Satoshi-Black';
`)

export const BigWhiteText = withTheme(styled.div`
  font-family: 'Satoshi-Black';
  color: ${_ => _.theme.primaryColor};
  font-size: 3.125rem;
  text-align: ${_ => _.textAlign || 'left'};
  line-height: 2.5rem;
`)
