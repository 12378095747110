import React from 'react'
import styled from 'styled-components'
import { Logo } from '../logo/Logo'
import { Options } from '../options/Options'
import { Link } from 'react-router-dom'
import { useDevice } from '../../context/device/DeviceContextProvider'

const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: ${props => props.isMobile && '10vw'};
`

const HeaderStyle = styled.div`
  display: ${props => props.isMobile ? 'grid' : 'flex'};
  justify-content: ${props => !props.isMobile && 'space-between'};
  grid-template-columns: ${props => props.isMobile && 'repeat(3, 1fr)'};
  align-items: ${props => !props.isMobile ? 'center' : 'flex-end'};
  font-size: 1.125rem;
  width: 100%;
`
const HeaderItem = styled.div`
  display: flex;
  // flex: ${_ => _.flexGrow || 1};
  justify-content: ${_ => _.textAlign || 'flex-start'};
  align-items: center;
  &.title {
    grid-column: 2 / 3;
    justify-content: center;
    padding-left: ${props => props.isMobile ? '0' : '50px'};
    margin-right: ${props => props.isMobile ? '0' : '100px'};
    margin-top: ${props => props.isMobile ? '15px' : '0'};
  }

  &.menu {
    grid-column: 3 / 4;
    justify-content: end;
    padding-right: ${props => props.isMobile ? '0px' : '50px'};
    padding-left: ${props => props.isMobile ? '5vw' : ''};
  }
`

export const Header = () => {
  const { isMobile } = useDevice()
  return (
    <Wrapper isMobile={isMobile}>
      <HeaderStyle isMobile={isMobile}>
        <HeaderItem isMobile={isMobile} className="title"><Link to={'/'}><Logo/></Link></HeaderItem>
        <HeaderItem isMobile={isMobile} className="menu" textAlign={'space-between'}>
          <Options style={{ marginLeft: 'auto' }} />
        </HeaderItem>
      </HeaderStyle>
    </Wrapper>
  )
}
