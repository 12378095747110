import styled from 'styled-components'
import { withTheme } from '../../theme'

export const RedText = withTheme(styled.span`
  font-family: Satoshi-Black;
  font-family: ${_ => _.isBlack && 'Satoshi-Black'};
  color: ${_ => _.theme.accentColor};
  font-size: ${_ => _.size || '2rem'};
  text-transform: ${_ => _.textTransform || 'none'};
  line-height: ${_ => _.lineHeight || '2rem'};
  margin-bottom: ${_ => _.marginBottom};
  margin-top: ${_ => _.marginTop};
`)

export const WhiteText = withTheme(styled.span`
  font-family: Satoshi-Black;
  font-family: ${_ => _.isBlack && 'Satoshi-Black'};
  color: #ffffff;
  font-size: ${_ => _.size || '2rem'};
  text-transform: ${_ => _.textTransform || 'none'};
  line-height: ${_ => _.lineHeight || '2rem'};
  margin-bottom: ${_ => _.marginBottom};
  margin-top: ${_ => _.marginTop};
  width: ${_ => _.width};
  text-align: ${_ => _.align};
`)
