import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text } from '../text/Text'
import { useDevice } from '../../context/device/DeviceContextProvider'

const SpriteParagraphStyle = styled.div`
  display: ${_ => _.isFlex && 'flex'};
  flex-direction: ${_ => _.isFlex && 'column'};
  align-items: center;
  justify-content: center;
`

const SpriteImageStyle = styled.img`
  z-index: 4;
  position: relative;
  width: 80vw;
  margin-top: 120px
`

const ParagraphContainer = styled.div`
  width: ${props => props.isMobile ? '80vw' : '80%'};

  font-weight: 700;
`

export const SpriteParagraph = ({ spriteName, text = [], textAlign = 'left', isFlex = false }) => {
  const { isMobile } = useDevice()
  return (
    <SpriteParagraphStyle isFlex={isFlex}>
        {isMobile && <SpriteImageStyle src={`/img/sprites/${spriteName}.png`}/>}
        {!isMobile && (<div>
          <img src={`/img/sprites/${spriteName}.png`}/>
        </div>)}
      <ParagraphContainer isMobile={isMobile}>
        {
          text.map((paragraph, i) => (
            <Text isMobile={isMobile} textAlign={textAlign} key={i}>{paragraph}</Text>
          ))
        }
      </ParagraphContainer>
    </SpriteParagraphStyle>
  )
}

SpriteParagraph.propTypes = {
  isFlex: PropTypes.bool,
  spriteName: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.string),
  textAlign: PropTypes.string
}
