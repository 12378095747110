import React from 'react'
import styled from 'styled-components'
import { withTheme } from '../../theme'
import { SocialLinks } from '../socialLinks/SocialLinks'
import { useDevice } from '../../context/device/DeviceContextProvider'
import PropTypes from 'prop-types'

const OptionsButton = styled.div`
  width: 30px;
  height: 30px;
  display:inline-block;
  padding:10px;
  background-color: #414246;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  display: flex; 
  justify-content: center;
  align-items: center;
`

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const OptionsBar = styled.div`
  width: 25px;
  height: 3px;
  background-color: #FFFFFF;
  margin: 3px 0;
`

const OptionsMenu = withTheme(styled.div`
  background-color: ${_ => _.theme.accentColor};
  font-family: 'Satoshi-Black';
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: ${props => props.isMobile ? '100vw' : '40vw'};
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`)

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 20px;
`

const OptionItem = withTheme(styled.a`
  color: ${_ => _.theme.primaryColor};
  font-size: 3.125rem;
  line-height: 3.4375rem;
  margin-block: 1rem;
  margin-bottom: ${props => props.isMobile ? '12vw' : ''};
`)

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`

const CloseButton = styled.span`
  width: 3.3125rem;
  height: 3.3125rem;
  font-size: 3.3125rem;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  padding: ${props => !props.isMobile && '5px 30px 0 0'};
`

const AdditionalLinks = withTheme(styled.div`
  color: ${_ => _.theme.primaryColor};
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0 20px;
`)

const AdditionalLink = withTheme(styled.a`
  color: ${_ => _.theme.primaryColor};
  margin-block: 1rem;
  margin-bottom: ${props => props.isMobile ? '7vw' : ''};
`)

export const Options = ({ walletAddress, connectWalletPressed, userTokenBalance }) => {
  const [open, setOpen] = React.useState(false)
  const { isMobile } = useDevice()
  return (
    <OptionsButton style={{ zIndex: 100, marginLeft: '50px' }} onClick={() => setOpen(!open)} walletAddress={walletAddress} connectWalletPressed={connectWalletPressed}>
      <BarWrapper>
        <OptionsBar/>
        <OptionsBar/>
        <OptionsBar/>
      </BarWrapper>
      {
        open &&
          <OptionsMenu onClick={() => setOpen(false)} isMobile={isMobile}>
            <MenuHeader isMobile={isMobile}>
              <CloseButton onClick={() => setOpen(false)} isMobile={isMobile}>
                <img style={{ width: '50px', height: '50px' }} src={'/img/icons/CloseButton.svg'}/>
              </CloseButton>
            </MenuHeader>
            <OptionList isMobile={isMobile}>
              <OptionItem isMobile={isMobile} href="/#about-project">About project</OptionItem>
              <OptionItem isMobile={isMobile} href="/#artworkslider">Artwork</OptionItem>
              <OptionItem isMobile={isMobile} href="/#about-us">About us</OptionItem>
            </OptionList>
            <AdditionalLinks isMobile={isMobile}>
              <AdditionalLink href='/wallpapers.zip' target='_blank'>Free Wallpapers</AdditionalLink>
            </AdditionalLinks>
            <SocialLinks isMenu={true}/>
          </OptionsMenu>
      }
    </OptionsButton>
  )
}

Options.propTypes = {
  walletAddress: PropTypes.string,
  connectWalletPressed: PropTypes.func,
  userTokenBalance: PropTypes.number
}
