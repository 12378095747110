import React from 'react'
import styled from 'styled-components'
import { RedText } from '../redText/RedText'
import PropTypes from 'prop-types'
import { Text } from '../text/Text'
import { useDevice } from '../../context/device/DeviceContextProvider'

const RedTitleParagraphStyle = styled.div`
`

const RedTitle = styled.div`
  text-align: ${_ => _.textAlign || 'left'};
`

const Paragraph = styled.div`
`

export const RedTitleParagraph = ({ title, text = [], textAlign = 'left', isBlack = false }) => {
  const { isMobile } = useDevice()
  return (
    <RedTitleParagraphStyle>
      <RedTitle textAlign={textAlign}>
        <RedText isBlack={isBlack} size={isMobile ? '40px' : '3.125rem'} lineHeight={isMobile ? '45px' : '3.5rem'}>{title}</RedText>
      </RedTitle>
      <Paragraph>
        {
          text.map((p, i) => (
            <Text key={i} textAlign={textAlign}>{p}</Text>
          ))
        }
      </Paragraph>
    </RedTitleParagraphStyle>
  )
}

RedTitleParagraph.propTypes = {
  title: PropTypes.string,
  isBlack: PropTypes.bool,
  text: PropTypes.arrayOf(PropTypes.node),
  textAlign: PropTypes.string
}
