import React from 'react'
import styled from 'styled-components'
import { SocialLinks } from '../socialLinks/SocialLinks'
import { withTheme } from '../../theme'
import { useDevice } from '../../context/device/DeviceContextProvider'

const FooterStyle = styled.footer`
  margin-top: ${_ => _.isMobile ? '55px' : '286px'};
  display: flex;
  width: 80vw;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${_ => _.isMobile ? 'column-reverse' : 'row'};
`

const Copyright = withTheme(styled.div`
  color: ${_ => _.theme.primaryColor};
  margin-top: ${_ => _.isMobile ? '55px' : 0};
  & a {
    color: #FF1F1F;
  } 
`)

export const Footer = () => {
  const { isMobile } = useDevice()
  return (
    <FooterStyle isMobile={isMobile}>
      <Copyright isMobile={isMobile}>We Are Satoshis © 2021 - 2023 | <a href='/tos.pdf' target='_blank'>Terms of Service</a></Copyright>
      <SocialLinks isMenu={false}/>
    </FooterStyle>
  )
}
