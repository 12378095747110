import React from 'react'

export const Logo = () => {
  return (
    <svg width="131" height="46" viewBox="0 0 131 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.83 0C15.36 0 18.9 3.39 19 8.3H13.21C13.21 6.19 11.83 5.24001 9.75 5.24001C7.46 5.24001 6.25 6.41 6.25 8.04C6.25 9.5 7.16 10.22 8.8 10.66L13 11.76C17.84 13.03 20.1 16.06 20.1 19.7C20.1 24.62 16.02 27.93 9.98 27.93C4.08 27.93 0.11 24.76 0 19.63H5.75C5.71 21.6 7.35 22.69 10.01 22.69C12.7 22.69 14.2 21.6 14.2 19.89C14.2 18.65 13.4 17.89 11.72 17.41L7.46 16.21C2.29 14.75 0.36 11.8 0.36 8.35C0.36 3.53 4.15 0 9.83 0Z" fill="white"/>
      <path d="M21.12 22.1C21.12 18.5 23.81 16.68 27.86 16.35L32.59 15.95V15.66C32.59 13.8 31.42 12.93 29.5 12.93C27.39 12.93 26.19 13.91 26.22 15.48H21.56C21.49 11.4 24.87 8.67001 29.79 8.67001C35.4 8.67001 38.05 11.95 38.05 16.83V27.46H33.1L32.74 25.06C32.16 26.7 30.16 27.94 27.39 27.94C23.49 27.93 21.12 25.63 21.12 22.1ZM32.62 20.65V19.67L29.78 19.96C27.56 20.18 26.83 20.72 26.83 21.85C26.83 23.09 27.63 23.78 29.34 23.78C30.29 23.78 32.62 23.56 32.62 20.65Z" fill="white"/>
      <path d="M41.91 27.45V13.91H38.45V9.25H41.91V3.61H47.4399V9.25H50.86V13.91H47.4399V27.45H41.91Z" fill="white"/>
      <path d="M60.6199 8.70001C66.4099 8.70001 70.2699 12.56 70.2699 18.31C70.2699 24.06 66.4099 27.89 60.6199 27.89C54.7899 27.89 50.9399 24.07 50.9399 18.31C50.9399 12.55 54.7999 8.70001 60.6199 8.70001ZM60.6199 22.87C63.0199 22.87 64.6199 21.05 64.6199 18.28C64.6199 15.55 63.0199 13.73 60.6199 13.73C58.1799 13.73 56.5799 15.55 56.5799 18.28C56.5799 21.05 58.1899 22.87 60.6199 22.87Z" fill="white"/>
      <path d="M75.92 21.7C75.92 22.97 76.79 23.74 78.43 23.74C79.85 23.74 80.69 23.16 80.69 22.25C80.69 21.63 80.29 21.19 79.34 20.94L76.14 20.1C72.54 19.15 71.19 17.15 71.19 14.71C71.19 11.07 74.21 8.67001 78.25 8.67001C82.25 8.67001 85.13 11.04 85.28 14.82H80.22C80.26 13.58 79.42 12.74 78.14 12.74C77.01 12.74 76.25 13.36 76.25 14.31C76.25 15.26 76.98 15.55 78.14 15.84L81.38 16.64C84.37 17.37 85.97 19.15 85.97 21.85C85.97 25.6 82.91 27.93 78.51 27.93C73.92 27.93 71.01 25.38 70.86 21.7H75.92Z" fill="white"/>
      <path d="M87.3199 27.45V0H92.9599V11.14C94.1299 9.54 96.1999 8.66 98.5299 8.66C103.12 8.66 105.37 11.68 105.37 16.2V27.45H99.7599V17.55C99.7599 14.02 97.4299 13.76 96.5599 13.76C95.5399 13.76 92.9199 14.01 92.9199 17.47V27.45H87.3199Z" fill="white"/>
      <path d="M107.38 3.21001C107.38 1.43001 108.8 0.0400085 110.58 0.0400085C112.33 0.0400085 113.75 1.42001 113.75 3.21001C113.75 4.99001 112.33 6.41001 110.58 6.41001C108.8 6.41001 107.38 4.99001 107.38 3.21001ZM107.74 9.25001H113.38V27.45H107.74V9.25001Z" fill="white"/>
      <path d="M120.2 21.7C120.2 22.97 121.07 23.74 122.71 23.74C124.13 23.74 124.97 23.16 124.97 22.25C124.97 21.63 124.57 21.19 123.62 20.94L120.42 20.1C116.82 19.15 115.47 17.15 115.47 14.71C115.47 11.07 118.49 8.67001 122.53 8.67001C126.53 8.67001 129.41 11.04 129.56 14.82H124.5C124.54 13.58 123.7 12.74 122.42 12.74C121.29 12.74 120.53 13.36 120.53 14.31C120.53 15.26 121.26 15.55 122.42 15.84L125.66 16.64C128.65 17.37 130.25 19.15 130.25 21.85C130.25 25.6 127.19 27.93 122.79 27.93C118.2 27.93 115.29 25.38 115.14 21.7H120.2Z" fill="white"/>
      <path d="M52.0701 38.33H49.3501V39.23C49.3501 42.2 47.7001 44.28 44.5601 45.37L43.6801 43.91C46.3701 43.16 47.7601 41.56 47.7601 39.23V38.33H44.5301V41.5H42.9501V38.33H40.3201V36.79H42.9501V34.12H44.5301V36.79H47.7601V34.02H49.3501V36.79H52.0701V38.33Z" fill="white"/>
      <path d="M61.5801 41.53C60.4001 40.51 59.1301 39.67 57.8101 39.01V45.07H56.1901V34.03H57.8101V37.23C59.6901 38.07 61.3401 39.08 62.6601 40.23L61.5801 41.53Z" fill="white"/>
      <path d="M68.56 40C67.72 39.35 66.77 38.78 65.55 38.25L66.46 37.07C67.64 37.53 68.64 38.09 69.46 38.78L68.56 40ZM76.32 39.31C73.72 42.24 70.42 44.17 66.81 44.96L65.99 43.45C69.5 42.8 72.82 40.89 75.52 37.95L76.32 39.31ZM70 36.87C69.03 36.22 67.99 35.66 66.86 35.22L67.81 34.05C68.98 34.48 69.99 35.02 70.95 35.67L70 36.87Z" fill="white"/>
      <path d="M83.0201 43.52C84.4101 43.33 85.4701 42.95 86.1601 42.34C86.9001 41.76 87.2601 40.97 87.2601 39.95C87.2601 38.86 86.9301 37.97 86.2601 37.3C85.6601 36.67 84.9201 36.32 84.0001 36.25C83.7701 38.65 83.3201 40.59 82.6801 42.02C81.9801 43.66 81.1301 44.45 80.0801 44.45C79.2801 44.45 78.6301 44.08 78.1301 43.34C77.6301 42.6 77.3801 41.73 77.3801 40.71C77.3801 38.99 77.9201 37.57 79.0401 36.44C80.1801 35.27 81.6901 34.69 83.5301 34.69C85.1901 34.69 86.5001 35.19 87.4601 36.18C88.4001 37.12 88.8501 38.4 88.8501 39.93C88.8501 41.41 88.3701 42.6 87.3601 43.51C86.4601 44.31 85.3001 44.78 83.8001 45L83.0201 43.52ZM79.9501 37.69C79.2501 38.53 78.9101 39.5 78.9101 40.66C78.9101 41.23 79.0401 41.74 79.2701 42.15C79.5101 42.56 79.7701 42.78 80.0901 42.78C80.4701 42.78 80.9101 42.21 81.3701 41.12C81.8501 39.85 82.2101 38.29 82.4401 36.34C81.5001 36.44 80.6401 36.87 79.9501 37.69Z" fill="white"/>
    </svg>
  )
}
