import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { withTheme } from '../../theme'
import { useDevice } from '../../context/device/DeviceContextProvider'

const ListStyle = styled.ul`
  list-style-type: none;
  padding-inline-start: 0;
  margin: 10px 0 0;
`

const ListItemStyle = withTheme(styled.li`
  color: ${_ => _.theme.primaryColor};
  border-bottom: 1px solid ${_ => _.theme.textColor};
  padding-block: 15px;
  line-height: ${_ => _.lineHeight || '2rem'}
`)

export const List = ({ children }) => {
  return (
    <ListStyle>
      {children}
    </ListStyle>
  )
}

List.propTypes = {
  children: PropTypes.node
}

export const ListItem = ({ children }) => {
  const { isMobile } = useDevice()
  return (
    <ListItemStyle lineHeight={isMobile ? '45px' : ''}>
      {children}
    </ListItemStyle>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
  isMobile: PropTypes.bool
}
