import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SatoshiStyle = styled.div`
  border-radius: ${_ => _.borderRadius || 0};
  //width: ${_ => _.size || '551px'};
  width: ${props => props.isMobile ? '100vw' : props.size};
  height: ${_ => _.size || '551px'};
  // aspect-ratio: 1 / 1;
  background-image: url("/img/satoshis/${_ => _.satoshi}.png");
  background-size: cover;
  background-position: center;
`

export const Satoshi = ({ name, isMobile, size = '551px', rounded = false, circle = false }) => {
  let borderRadius = '0px'
  if (rounded) {
    borderRadius = '10px'
  }
  if (circle) {
    borderRadius = '50%'
  }
  return (
    <SatoshiStyle
      satoshi={name}
      size={size}
      borderRadius={borderRadius}
      isMobile={isMobile}/>
  )
}

Satoshi.propTypes = {
  size: PropTypes.string,
  rounded: PropTypes.bool,
  name: PropTypes.string,
  circle: PropTypes.bool,
  isMobile: PropTypes.bool
}
