import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const VideoEmbedStyle = styled.div`
`

const IFrame = styled.iframe`
  width: ${_ => _.width};
  aspect-ratio: ${_ => _.aspectRatio};
`

export const VideoEmbed = ({ width = '445px', aspectRatio = '16 / 9', src }) => {
  return (
    <VideoEmbedStyle>
      <IFrame
        width={width}
        aspectRatio={aspectRatio}
        src={src}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"/>
    </VideoEmbedStyle>
  )
}

VideoEmbed.propTypes = {
  width: PropTypes.string,
  aspectRatio: PropTypes.string,
  src: PropTypes.string
}
