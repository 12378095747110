import { React, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { Main } from './components/main/Main'
import { Header } from './components/header/Header'
import { Footer } from './components/footer/Footer'
import { HomePage } from './pages/homePage/HomePage'
import { DeviceContextProvider } from './context/device/DeviceContextProvider'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {
  getUserTokenBalance,
  claimAirdrop
} from './utils/web3.js'

function App () {
  const [userTokenBalance, setUserTokenBalance] = useState(0)

  if (window.location.hostname !== 'localhost' && window.location.hostname !== 'dev.wearesatoshis.com') {
    console.log = () => {}
  }

  const claimTokens = async () => {
    const airdropClaim = await claimAirdrop()
    if (airdropClaim) {
      const tokenBalance = await getUserTokenBalance()
      const newUserTokenBalance = tokenBalance / 1000000000000000000
      setUserTokenBalance(newUserTokenBalance)
    }
  }

  return (
    <Router>
      <DeviceContextProvider>
        <Main>
          <Header claimTokens={claimTokens} userTokenBalance={userTokenBalance}/>
          <Switch>
            <Route path={'/'}>
              <HomePage/>
            </Route>
          </Switch>
          <Footer/>
          <ToastContainer/>
        </Main>
      </DeviceContextProvider>
    </Router>
  )
}

export default App
