import React from 'react'
import styled from 'styled-components'
import { RedTitleParagraph } from '../redTitleParagraph/RedTitleParagraph'
import { Satoshi } from '../satoshi/Satoshi'
import { useDevice } from '../../context/device/DeviceContextProvider'
// import ReactSlidy from 'react-slidy'
// import 'react-slidy/lib/styles.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const ArtworkStyle = styled.div`
  margin-top: ${props => props.isMobile ? '150px' : '250px'};
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ArtworkHeader = styled.div`
  width: ${props => props.isMobile ? '80vw' : '50%'};
`

const ArtworkSlider = styled.div`
  width: ${props => props.isMobile ? '100vw' : '150vw'};
  margin-top: 135px;
`

export const Artwork = () => {
  const { isMobile } = useDevice()
  return (
    <ArtworkStyle id='artwork' isMobile={isMobile}>
      <ArtworkHeader isMobile={isMobile}>
        <RedTitleParagraph
          isBlack={true}
          textAlign={'center'}
          title={'Artwork'}
          text={['1024 unique Satoshi rebels. We will manually inspect the entire collection before revealing it to ensure they are truly unique. Our community means the world to us and we will do everything in our power to deliver the highest quality artwork to each of the owners. Satoshis come in several rarity classes: Common, Rare, Super-Rare, and One Of A Kind. Resurrecting a Satoshi is upgrading its rarity class to Epic and Legendary.']}/>
      </ArtworkHeader>
      <ArtworkSlider isMobile={isMobile} id="artworkslider">
          <Carousel transitionTime={1000} autoPlay showArrows={false} infiniteLoop={true} emulateTouch={true} swipeable={true} showIndicators={false} showThumbs={false} showStatus={false} centerMode={true} centerSlidePercentage={isMobile ? 100 : 30}>
            <Satoshi name={'Satoshis-01'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-02'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-03'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-04'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-05'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-06'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-07'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-08'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-10'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-11'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-12'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-13'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-14'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-15'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-16'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-17'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-18'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-21'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-23'} isMobile={isMobile}/>
            <Satoshi name={'Satoshis-24'} isMobile={isMobile}/>
        </Carousel>
      </ArtworkSlider>
    </ArtworkStyle>
  )
}
