import React from 'react'
import styled from 'styled-components'
import { WhiteText } from '../whiteText/WhiteText'
import { Text } from '../text/Text'
import { useDevice } from '../../context/device/DeviceContextProvider'

const WeBelieveStyle = styled.div`
  width: 64vw;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const WhitePaperLink = styled.a`
  text-align: center;
`
const Paragraph = styled.div`
  text-align: center;
  width: ${props => props.isMobile ? '80vw' : '100%'};
`

export const WeBelieve = () => {
  const { isMobile } = useDevice()
  return (
    <WeBelieveStyle>
      <Paragraph isMobile={isMobile}>
        <Text textAlign={'center'} fontSize={isMobile ? '18px' : '1.875rem'} isBold={true} lineHeight={isMobile ? '22px' : '2.1875rem'}>
          We believe Bitcoin and Cryptocurrencies represent (financial) freedom and justice for an ordinary person. Corruption and inflation both cause great misery and suffering. Read our Whitepaper for an in-depth understanding of our mission, and our values.
        </Text>
      </Paragraph>
      <br/>
      <WhitePaperLink href='https://medium.com/@WeAreSatoshis/by-the-community-for-the-people-75b6c56344ea' target='_blank'>
        <WhiteText>White Paper</WhiteText>
        <img src={'/img/sprites/ArrowRight.png'}/>
      </WhitePaperLink>
    </WeBelieveStyle>
  )
}
