import styled from 'styled-components'
import { withTheme } from '../../theme'

export const BlackText = withTheme(styled.span`
  font-family: Satoshi-Black;
  font-weight: 900;
  font-size: ${_ => _.fontSize || '8.125rem'};
  line-height: ${_ => _.lineHeight || '7.5rem'};
  letter-spacing: -0.03em;
  color: ${_ => _.theme.backgroundColor}
  `)
