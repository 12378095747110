import React from 'react'
import styled from 'styled-components'
import { useDevice } from '../../context/device/DeviceContextProvider'

const Wrapper = styled.div`
  margin-top: ${_ => _.isMobile ? '' : '100px'};
  width: ${_ => _.isMobile ? '423px' : '1439px'};
  height: ${_ => _.isMobile ? '140px' : '423px'};
  background-image: url("/img/sprites/JoinTheMovement-${_ => _.isMobile ? 'Mobile' : 'Desktop'}.png");
  background-size: cover;
`

export const JoinTheMovement = () => {
  const { isMobile } = useDevice()
  return (
    <Wrapper isMobile={isMobile}>
    </Wrapper>
  )
}
