import React from 'react'
import styled from 'styled-components'
import { useDevice } from '../../context/device/DeviceContextProvider'
import PropTypes from 'prop-types'

const SocialLinksStyle = styled.div`
  margin-top: 6px;
  
`

const SocialLink = styled.a`
  margin-inline: 16px;
  margin-right: ${props => props.isMobile ? '7vw' : ''};
  padding-left: ${props => (props.isMenu && props.isMobile) ? '5vw' : ''};
`

/**
 * @todo Skloniti margine kad se popravi Discord slika. Ta slika sada ima puno praznog prostora na dnu
 * @returns {JSX.Element}
 * @constructor
 */
export const SocialLinks = ({ isMenu }) => {
  const { isMobile } = useDevice()
  return (
    <SocialLinksStyle>
      <SocialLink isMobile={isMobile} isMenu={isMenu}
        target={'_blank'}
        href={'https://twitter.com/WeAreSatoshis'}>
        <img src={'/img/icons/Twitter.svg'}/>
      </SocialLink>
      <SocialLink
        target={'_blank'}
        href={'https://opensea.io/collection/wearesatoshisnft'}>
        <img src={'/img/icons/Opensea.svg'}/>
      </SocialLink>
    </SocialLinksStyle>
  )
}

SocialLink.propTypes = {
  isMenu: PropTypes.bool
}
SocialLinks.propTypes = {
  isMenu: PropTypes.bool
}
