import React from 'react'
import styled from 'styled-components'
import { BigWhiteText } from '../whiteText/WhiteText'
import { Satoshi } from '../satoshi/Satoshi'
import { withTheme } from '../../theme'
import { useDevice } from '../../context/device/DeviceContextProvider'

const TeamStyle = styled.div`
  width: 80vw;
  margin-top: 175px;
`

const Members = styled.div`
  margin-top: 60px;
  display: flex;
  flex-direction: ${props => props.isMobile && 'column'};
  justify-content: space-between;
`

const TeamMember = styled.div`
  width: 20vw;
  margin-bottom: ${props => props.isMobile && '120px'}
`

const Name = withTheme(styled.div`
  font-family: Satoshi-Black;
  color: ${_ => _.theme.primaryColor};
  margin-top: ${props => props.isMobile ? '20px' : '58px'};
  margin-bottom: 30px;
`)

const Description = styled.div`
  font-family: Satoshi-Medium;
  font-weight: 500;
  width: ${props => props.isMobile && '80vw'}
`

export const Team = () => {
  const { isMobile } = useDevice()
  return (
    <TeamStyle id='about-us'>
      <BigWhiteText>Team</BigWhiteText>
      <Members isMobile={isMobile}>
        <TeamMember isMobile={isMobile}>
          <Satoshi circle name={'Vlada'} size={'250px'}/>
          <Name isMobile={isMobile}>Vlada</Name>
          <Description isMobile={isMobile}>
            Lead artist. Graphic designer, ex graffiti artist.
          </Description>
        </TeamMember>
        <TeamMember isMobile={isMobile}>
          <Satoshi circle name={'Marko'} size={'250px'}/>
          <Name isMobile={isMobile}>Marko</Name>
          <Description isMobile={isMobile}>
            Graphic designer, graffiti artist, illustrator and above all a great friend!
          </Description>
        </TeamMember>
        <TeamMember isMobile={isMobile}>
          <Satoshi circle name={'Ivan'} size={'250px'}/>
          <Name isMobile={isMobile}>Ivan</Name>
          <Description isMobile={isMobile}>
            Stargazer. Geek. Startup veteran.
          </Description>
        </TeamMember>
      </Members>
    </TeamStyle>
  )
}
