import React from 'react'
import styled from 'styled-components'
import { BigWhiteText } from '../whiteText/WhiteText'
import { List, ListItem } from '../list/List'
import { TextInline } from '../text/Text'
import { useDevice } from '../../context/device/DeviceContextProvider'

const Wrapper = styled.div`
  margin-top: ${props => props.isMobile ? '150px' : '250px'};
  width: 80vw;
  max-width: 1080px;
`

const SubSection = styled.div`
  margin-bottom: ${props => props.isMobile ? '150px' : '170px'};
  display: flex;
  grid-template-columns: ${props => props.isMobile ? '' : '1fr 1fr 1fr'};
  flex-direction: ${props => props.isMobile && 'column'};
`

const Title = styled.div`
  flex: 1;
  margin-top: 20px;
  texta-lign: left;
  margin-bottom: ${props => props.isMobile ? '10vw' : ''}
`

const ListCol = styled.div`
  flex: 1.6;
  padding-top: 15px;
`

export const SummaryAndTimeline = () => {
  const { isMobile } = useDevice()
  return (
    <Wrapper isMobile={isMobile}>
      <SubSection isMobile={isMobile}>
        <Title isMobile={isMobile}>
          <BigWhiteText>Summary</BigWhiteText>
        </Title>
        <ListCol>
          <TextInline isBold={true}>Here is an itemized list of what you can expect to get:</TextInline>
          <List>
            <ListItem>&nbsp;1. Unique We Are Satoshis NFT</ListItem>
            <ListItem>&nbsp;2. Limited edition hoodie</ListItem>
            <ListItem>&nbsp;3. Limited edition poster</ListItem>
            <ListItem>&nbsp;4. Unlimited number of family photos</ListItem>
            <ListItem>&nbsp;5. One unique companion NFT</ListItem>
            <ListItem>&nbsp;6. One unique arch-enemy NFT</ListItem>
            <ListItem>&nbsp;7. PFP maker</ListItem>
            <ListItem>&nbsp;8. Wallpapers</ListItem>
            <ListItem>9. Utility tokens</ListItem>
            <ListItem>10. A vote in making decisions on community&apos;s charitable activities</ListItem>
          </List>
        </ListCol>
      </SubSection>
      <SubSection isMobile={isMobile}>
        <Title isMobile={isMobile}>
          <BigWhiteText>Timeline</BigWhiteText>
        </Title>
        <ListCol isMobile={isMobile}>
          <TextInline isBold={true}> Here is what we plan and when we plan to do it:
          </TextInline>
          <List>
            <ListItem>&nbsp;1. Initial mint, pre-sale (November)</ListItem>
            <ListItem>&nbsp;2. Rarity rankings published on Rarity.tools and other websites.</ListItem>
            <ListItem>&nbsp;3. Hoodies & Posters delivery (December)</ListItem>
            <ListItem>&nbsp;4. Vinyl toys delivery (if possible, March 2022)</ListItem>
            <ListItem>&nbsp;5. Charitable donations to the causes voted by the community (December)</ListItem>
            <ListItem>&nbsp;6. Family photo feature (January 2022)</ListItem>
            <ListItem>&nbsp;7. Utility token: airdrop & claiming (February/March 2022)</ListItem>
            <ListItem>&nbsp;8. Satoshis heaven / Resurrection (March/April 2022)</ListItem>
            <ListItem>&nbsp;9. Merch (Q2 2022)</ListItem>
            <ListItem>10. Satoshi Sidekick NFT drop (June 2022)</ListItem>
            <ListItem>11. Anti-Satoshi NFT drop (August 2022)</ListItem>
          </List>
        </ListCol>
      </SubSection>
    </Wrapper>
  )
}
