import React from 'react'
import styled from 'styled-components'
import { SpriteParagraph } from '../spriteParagraph/SpriteParaghraph'
import { useDevice } from '../../context/device/DeviceContextProvider'

const WhyHowWhatSectionStyle = styled.div`
  margin-top: ${props => props.isMobile ? '50px' : '120px'};
  width: ${props => props.isMobile ? '100vw' : ''};
`

const Row = styled.div`
  display: flex;
  margin-top: ${_ => _.marginTop || '70px'};
  justify-content: ${_ => _.justify || 'flex-start'};
  height: ${_ => _.isMobile ? '500px' : '700px'};
`

const TextContainer = styled.div`
  flex: ${_ => _.flex || 1};
  padding-left: ${_ => _.paddingLeft || 0};
  width: ${props => props.isMobile && '64vw'};
  
  &#what{
    text-align: center;
  }
`

const SatoshiContainer = styled.div`
  position: relative;
  background-image: url("/img/satoshis/${_ => _.satoshi}.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: ${_ => _.position || 'left'};
  flex: 1;
`

// const SatoshiContainerRightMobile = styled.img`
//   width: 50vw;
// `
//
// const SatoshiContainerLeftMobile = styled.img`
//   width: 50vw;
// `

export const WhyHowWhatSection = () => {
  const { isMobile } = useDevice()
  if (isMobile) {
    return <WhyHowWhatSectionMobile/>
  }
  return (
    <WhyHowWhatSectionStyle id='about-project' isMobile={isMobile}>
      <Row id={isMobile ? 'why' : ''} isMobile={isMobile} style={{ marginTop: 200 }}>
        <TextContainer id="textContainer" paddingLeft={!isMobile && '13vw'} isMobile={isMobile}>
          <SpriteParagraph spriteName={'Why'} text={texts.why}/>
        </TextContainer>
        <SatoshiContainer satoshi={'BlackRotated'} position={'right'}/>
      </Row>
      <Row id={isMobile ? 'how' : ''} style={{ marginTop: 200 }}>
        <SatoshiContainer satoshi={'OrangeRotated'}/>
        <TextContainer
          id="textContainer"
          style={{ marginTop: -120 }}
          paddingLeft={!isMobile && '12vw'}
          isMobile={isMobile}>
          <SpriteParagraph spriteName={'How'} text={texts.how}/>
        </TextContainer>
      </Row>
      <Row id={'what'} justify={'center'} marginTop={'150px'}>
        <TextContainer flex={0.5} isMobile={isMobile} >
          <SpriteParagraph isFlex={true} spriteName={'What'} text={texts.what} textAlign={'center'}/>
        </TextContainer>
      </Row>
    </WhyHowWhatSectionStyle>
  )
}

const RowMobile = styled.div`
  display: flex;
  justify-content: ${_ => _.justify || 'center'};
  //height: 500px;
  background-image: url("/img/satoshis/${_ => _.satoshi}.png");
  background-size: 290px 500px;
  background-repeat: no-repeat;
  background-position: ${_ => _.position ?? 'right top'};
  padding-inline: 70px
`

export const WhyHowWhatSectionMobile = () => {
  const { isMobile } = useDevice()
  return (
    <WhyHowWhatSectionStyle id='about-project' isMobile={isMobile}>
      <RowMobile satoshi={'BlackRotated'} style={{ paddingTop: 200 }}>
        <TextContainer id="textContainer" isMobile={isMobile}>
          <SpriteParagraph spriteName={'Why'} text={texts.why} isFlex={true}/>
        </TextContainer>
      </RowMobile>
      <RowMobile>
        <TextContainer
          id="textContainer"
          isMobile={isMobile}>
          <SpriteParagraph spriteName={'How'} text={texts.how} isFlex={true}/>
        </TextContainer>
      </RowMobile>
      <RowMobile satoshi={'OrangeRotated'} position='left bottom' style={{ height: 500, marginTop: 150 }}/>
      <RowMobile justify={'center'}>
        <TextContainer flex={0.5} isMobile={isMobile} >
          <SpriteParagraph isFlex={true} spriteName={'What'} text={texts.what} textAlign={'center'}/>
        </TextContainer>
      </RowMobile>
    </WhyHowWhatSectionStyle>
  )
}

const texts = {
  why: [
    'We embarked on this journey to make a different kind of NFT. One that does not only have a monetary potential and flip value but one that has a deeper meaning and a purpose.', 'By standing strong against usual money grabs in the NFT space, we strive to bring real value to the community and NFT owners through the abundance of interactive NFTs with incredible artwork and physical products.'
  ],
  how: [
    'Creativity, Art, Innovation, and Hard Work. What sets us apart from the other NFT collections is the potential we have to expand to markets outside of the NFT space.', 'Features such as The Family Photo and Satoshi Resurrection are the innovation we bring to the NFT space and the way we provide long-term value for the NFT owners.', 'The way we treat our community & what we all do together to make this world a better place is what gives us purpose.'
  ],
  what: [
    'We Are Satoshis is an ERC721 token. OpenSea will be the main trading platform while the mint will happen right here, on this website :).', 'People who mint one of our NFTs can expect to get the following: Unique We Are Satoshis NFT, Limited edition We Are Satoshis hoodie, Limited edition We Are Satoshis poster, an Unlimited number of family photo NFTs, One unique companion NFT, One unique arch-enemy NFT, and Utility Tokens. Yes, every day is Christmas :)'
  ]
}
