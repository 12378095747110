require('dotenv').config()
const Web3 = require('web3')
const { ethers } = require('ethers')

// Loadconfig vars
const apiKey = process.env.REACT_APP_WSS_KEY
const apiHTTPKey = process.env.REACT_APP_HTTP_KEY
const IPFSGateway = process.env.REACT_APP_IPFS_GATEWAY
const API_URL = process.env.REACT_APP_API_LINK

export const web3 = new Web3(apiKey)
export const web3Http = new Web3(apiHTTPKey)

// Use the mainnet
const network = process.env.REACT_APP_ETH_NETWORK // 'rinkeby'

// Specify your own API keys
// Each is optional, and if you omit it the default
// API key for that service will be used.
const provider = ethers.getDefaultProvider(network, {
  infura: process.env.REACT_APP_INFURA_PROJECT_ID,
  etherscan: process.env.REACT_APP_ETHERSCAN_KEY
})

const contractAbi = require('./SatoshisABI.json')
const utilityTokenAbi = require('./WAS.json')
const villainAbi = require('./Villain.json')
const companionAbi = require('./companion.json')

export const contractAddress = process.env.REACT_APP_SMART_CONTRACT_ADDRESS
export const utilityTokenContractAddress = process.env.REACT_APP_UTILITY_TOKEN_CONTRACT
export const villainContractAddress = process.env.REACT_APP_VILLAIN_CONTRACT
export const companionContractAddress = process.env.REACT_APP_COMPANION_CONTRACT

// console.log('Villain conract', villainContractAddress)

// export const openSeaUrl = process.env.REACT_APP_OPENSEA;
// const nodeApiUrl = process.env.REACT_APP_API_URL;
// const ethNetwork = process.env.REACT_APP_ETHNETWORK;

export const nftCollectionContract = new web3.eth.Contract(contractAbi.abi, contractAddress)
export const nftCollectionContractHTTP = new web3Http.eth.Contract(contractAbi.abi, contractAddress)

export const utilityTokenContract = new web3.eth.Contract(utilityTokenAbi.abi, utilityTokenContractAddress)
export const utilityTokenContractHTTP = new web3Http.eth.Contract(utilityTokenAbi.abi, utilityTokenContractAddress)

export const villainTokenContract = new web3.eth.Contract(villainAbi.abi, villainContractAddress)
export const villainTokenContractHTTP = new web3Http.eth.Contract(villainAbi.abi, villainContractAddress)

export const companionTokenContract = new web3.eth.Contract(companionAbi.abi, companionContractAddress)
export const companionTokenContractHTTP = new web3Http.eth.Contract(companionAbi.abi, companionContractAddress)

export const getHoodieStatus = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return { status: false }
  }
  const hoodieStatus = await fetch(API_URL + '/get-hoodie-info/' + address.address, { method: 'GET' }).then(res => res.json()).then((result) => { return result })
  // console.log('Hoodie status web3', hoodieStatus)
  if (hoodieStatus.status) {
    return {
      is_eligible: hoodieStatus.is_eligible,
      name: hoodieStatus.name,
      email: hoodieStatus.email,
      confirmation_code: hoodieStatus.confirmation_code,
      is_confirmed: hoodieStatus.is_confirmed
    }
  } else {
    return { is_eligible: false }
  }
}

export const updateHoodieStatus = async (confirmationCode) => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return { status: false }
  }
  const hoodieUpdate = await fetch(API_URL + '/confirm-hoodie-entry/' + address.address + '/' + confirmationCode, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  // console.log('hoodie update status', hoodieUpdate)
  return hoodieUpdate
}
export const getUserFamilyPhotos = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return { pendingPhotos: [], completedPhotos: [], sharedPhotos: [] }
  }

  const userFamilyPhotos = await fetch(API_URL + '/get-family-photos/' + address.address, { method: 'GET' }).then(res => res.json()).then((result) => { return result })
  return { pendingPhotos: userFamilyPhotos.pending, completedPhotos: userFamilyPhotos.complete, sharedPhotos: userFamilyPhotos.shared }
}

export const getSingleFamilyPhotoMetData = async (photoId) => {
  const userFamilyPhoto = await fetch(API_URL + '/get-single-family-photo/' + photoId, { method: 'GET' }).then(res => res.json()).then((result) => { return result })
  // console.log('web3 single result', userFamilyPhoto)
  return userFamilyPhoto
}

export const getSingleFamilyPhotoMetDataByInvitationCode = async (address, photoId, usingInviteCode, inviteCode) => {
  if (usingInviteCode === false) {
    inviteCode = 'none'
    // console.log('Not using invitation code')
  }

  const userFamilyPhoto = await fetch(API_URL + '/get-single-family-photo/' + address + '/' + photoId + '/' + inviteCode, { method: 'GET' }).then(res => res.json()).then((result) => { return result })
  // console.log('web3 single result', userFamilyPhoto)
  return userFamilyPhoto
}

export const validateInviteCode = async (invitationCode) => {
  const photoId = await fetch(API_URL + '/validate-invitation-code/' + invitationCode, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  // console.log('Validate invitation code', photoId)
  return photoId.family_photo_id
}

export const addInviteeToFamilyPhoto = async (address, photoId) => {
  const addInviteeResult = await fetch(API_URL + '/add-invitee/' + address + '/' + photoId, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  console.log('Reulztat dodavanja adrese kao invitee-a', addInviteeResult)
}

export const getNewFamilyPhotoId = async (address) => {
  if (address === '') {
    return false
  }

  const newFamilyPhotoData = await fetch(API_URL + '/new-family-photo/' + address, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  return newFamilyPhotoData
}

export const updateFamilyPhoto = async (address, photoId, items, reorder, action) => {
  if (address === 0) {
    address = await getCurrentWalletAddress()
  }
  // console.log('items iz reordera narocito', items)
  if (items.length === 0 && photoId === 0) {
    return false
  }
  const itemIds = []
  for (const item of items) {
    itemIds.push(item.id)
  }
  const itemIdsText = itemIds.join()
  // console.log('itemIdsText', itemIdsText)
  const newPhotoMetaData = await fetch(API_URL + '/update-family-photo/' + address + '/' + photoId + '/' + itemIdsText + '/' + reorder + '/' + action, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  // console.log('response od snimanaj fotke', newPhotoMetaData)
  return newPhotoMetaData
}

export const markPhotoAsComplete = async (photoId, address) => {
  const markPhoto = await fetch(API_URL + '/mark-photo-complete/' + photoId + '/' + address, { method: 'POST' }).then(res => res.json()).then((result) => { return result })
  console.log('mark photo complete result', markPhoto)
}
export const getUserVillains = async () => {
  const address = await getCurrentWalletAddress()
  let userAddress = ''
  if (address.address === '') {
    return []
  } else {
    userAddress = address.address
  }
  // console.log('fetching user villains for address', userAddress)
  const userBalance = await villainTokenContractHTTP.methods.balanceOf(userAddress).call()
  const userTokens = []
  if (userBalance > 0) {
    for (let i = 0; i < userBalance; i++) {
      const tokenId = await villainTokenContractHTTP.methods.tokenOfOwnerByIndex(userAddress, i).call()
      const singleTokendata = await fetch('https://api.wearesatoshis.com' + '/villain-info/' + tokenId, { method: 'GET' }).then(res => res.json()).then((result) => {
        return result
      })
      userTokens.push({ token_id: tokenId, name: singleTokendata.name, image: singleTokendata.image.replace('ipfs://', IPFSGateway), tiny: 'https://static.wearesatoshis.com/tiny-villain/' + tokenId + '.png' })
    }
  }

  // console.log('User villains from web3 method', userTokens)
  return userTokens
}
export const getUserCompanions = async () => {
  const address = await getCurrentWalletAddress()
  let userAddress = ''
  if (address.address === '') {
    return []
  } else {
    userAddress = address.address
  }
  // console.log('fetching user villains for address', userAddress)
  const userBalance = await companionTokenContractHTTP.methods.balanceOf(userAddress).call()
  const userTokens = []
  if (userBalance > 0) {
    for (let i = 0; i < userBalance; i++) {
      const tokenId = await companionTokenContractHTTP.methods.tokenOfOwnerByIndex(userAddress, i).call()
      const singleTokendata = await fetch('https://api.wearesatoshis.com' + '/companion-info/' + tokenId, { method: 'GET' }).then(res => res.json()).then((result) => {
        return result
      })
      userTokens.push({ token_id: tokenId, name: singleTokendata.name, image: singleTokendata.image, tiny: 'https://static.wearesatoshis.com/tiny-nfts/' + tokenId + '.png' })
    }
  }

  // console.log('User villains from web3 method', userTokens)
  return userTokens
}
export const getUserTokens = async (getHellSatoshis) => {
  // Get user balance balanceOfssh api@
  let userAddress = ''
  if (getHellSatoshis === true) {
    // console.log('fetching satoshis in hell')
    userAddress = utilityTokenContractAddress
  } else {
    const address = await getCurrentWalletAddress()
    if (address.address === '') {
      return []
    } else {
      userAddress = address.address
    }
  }
  // console.log('Get satoshis for address', userAddress)

  const userBalance = await nftCollectionContractHTTP.methods.balanceOf(userAddress).call()
  const userTokens = []
  // console.log('Adresa ima tokena', userBalance)
  // While manje od balance, get token tokenOfOwnerByIndex, ovo vraca id na osnovu kojeg znamo metadata url
  if (userBalance > 0) {
    for (let i = 0; i < userBalance; i++) {
      const tokenId = await nftCollectionContractHTTP.methods.tokenOfOwnerByIndex(userAddress, i).call()
      const singleTokendata = await fetch(API_URL + '/token-info/' + tokenId + '/' + userAddress, { method: 'GET' }).then(res => res.json()).then((result) => {
        return result
      })

      let tinyFolder = 'tiny-satoshis'
      let tokenVersion = 'genesis'
      if (singleTokendata.is_hell_version !== undefined) {
        if (singleTokendata.is_hell_version === 1) {
          tinyFolder = 'tiny-hell'
          tokenVersion = 'hell'
        }
      }

      if (singleTokendata.is_heaven_version !== undefined) {
        if (singleTokendata.is_heaven_version === 1) {
          tinyFolder = 'tiny-heaven'
          tokenVersion = 'heaven'
        }
      }

      if (getHellSatoshis) {
        const inHell = await utilityTokenContractHTTP.methods.isSatoshiInHell(tokenId).call()
        // console.log('is token in hell', [tokenId, inHell])
        if (inHell) {
          if (singleTokendata.name !== undefined) {
            userTokens.push({ token_id: tokenId, version: tokenVersion, verification_code: singleTokendata.verification_code, name: singleTokendata.name, image: singleTokendata.image.replace('ipfs://', IPFSGateway), tiny: 'https://static.wearesatoshis.com/' + tinyFolder + '/nft-' + tokenId + '.png' })
          }
        }
      } else {
        if (singleTokendata.name !== undefined) {
          userTokens.push({ token_id: tokenId, version: tokenVersion, verification_code: singleTokendata.verification_code, name: singleTokendata.name, image: singleTokendata.image.replace('ipfs://', IPFSGateway), tiny: 'https://static.wearesatoshis.com/' + tinyFolder + '/nft-' + tokenId + '.png' })
        }
      }
    }
  }
  // onda za svaki token ocitati metadata url
  return userTokens
}

export const getVillainClaimAmount = async () => {
  // Get satoshis of this user

  const userSatoshis = await getUserTokens(false)
  let claimableVillainCount = 0
  for (const singleSatoshi of userSatoshis) {
    const satoshiId = singleSatoshi.token_id
    // console.log('Checking if satoshi claimed a villain', satoshiId)
    const isSatoshiClaimed = await villainTokenContractHTTP.methods.didSatoshiClaimAVillain(satoshiId).call()
    // console.log('is satoshi claimed ' + satoshiId, isSatoshiClaimed)
    if (isSatoshiClaimed === false) {
      claimableVillainCount++
      // console.log('Satoshi ' + satoshiId + ' can claim a villain')
    }
  }
  return claimableVillainCount
}

export const getCompanionClaimAmount = async () => {
  // Get satoshis of this user

  const userSatoshis = await getUserTokens(false)
  let claimableCompanionCount = 0
  for (const singleSatoshi of userSatoshis) {
    const satoshiId = singleSatoshi.token_id
    // console.log('Checking if satoshi claimed a villain', satoshiId)
    const isSatoshiClaimed = await companionTokenContractHTTP.methods.didSatoshiClaimACompanion(satoshiId).call()
    // console.log('is satoshi claimed ' + satoshiId, isSatoshiClaimed)
    if (isSatoshiClaimed === false) {
      claimableCompanionCount++
      // console.log('Satoshi ' + satoshiId + ' can claim a villain')
    }
  }
  return claimableCompanionCount
}

export const claimVillains = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return false
  }

  const transactionParameters = {
    from: address.address,
    to: villainContractAddress,
    data: villainTokenContractHTTP.methods.claim(address.address).encodeABI()
  }

  // Sign the transaction using Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    // Ako je transakcija uspela, smanji broj wl mesta u bazi, ako nje, resetuj
    const txResultStatus = await provider.waitForTransaction(txHash).then((result) => {
      return result
    })
    if (txResultStatus.status === 1) {
      // console.log('Villains claimed', txHash)
    }
    return true
  } catch (error) {
    // console.log('Villain claim failed')
    return false
  }
}

export const claimCompanions = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return false
  }

  const transactionParameters = {
    from: address.address,
    to: companionContractAddress,
    data: companionTokenContractHTTP.methods.claim(address.address).encodeABI()
  }

  // Sign the transaction using Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    // Ako je transakcija uspela, smanji broj wl mesta u bazi, ako nje, resetuj
    const txResultStatus = await provider.waitForTransaction(txHash).then((result) => {
      return result
    })
    if (txResultStatus.status === 1) {
      // console.log('Villains claimed', txHash)
    }
    return true
  } catch (error) {
    // console.log('Villain claim failed')
    return false
  }
}

export const getUserAirdrop = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return []
  }
  const airdropAmount = await utilityTokenContractHTTP.methods.getClaimableTokensAmount(address.address).call()
  // console.log('Address ', address.address, ' can claim: ', airdropAmount, ' tokens')
  return airdropAmount
}
export const getSelfResurrectionCost = async () => {
  const selfResurrectionCost = await utilityTokenContractHTTP.methods.getSelfResurrectionCost().call()
  const actualCost = (selfResurrectionCost / 1000000000000000000)
  // console.log('Self resurrection cost', actualCost)
  return actualCost
}

export const getPublicResurrectionCost = async () => {
  const publicResurrectionCost = await utilityTokenContractHTTP.methods.getPublicResurrectionCost().call()
  const actualCost = (publicResurrectionCost / 1000000000000000000)
  // console.log('Public resurrection cost', actualCost)
  return actualCost
}
export const getUserTokenBalance = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return []
  }
  const tokenBalance = await utilityTokenContractHTTP.methods.balanceOf(address.address).call()
  // console.log('token balance web3', tokenBalance)
  return tokenBalance
}

export const claimAirdrop = async () => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return false
  }

  const transactionParameters = {
    from: address.address,
    to: utilityTokenContractAddress,
    data: utilityTokenContractHTTP.methods.claimAirdrop(address.address).encodeABI()
  }

  // Sign the transaction using Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    // Ako je transakcija uspela, smanji broj wl mesta u bazi, ako nje, resetuj
    const txResultStatus = await provider.waitForTransaction(txHash).then((result) => {
      return result
    })
    if (txResultStatus.status === 1) {
      // console.log('Airdrop claimed', txHash)
    }
    return true
  } catch (error) {
    // console.log('Airdrop claim failed')
    return false
  }
}

export const resurrectPublic = async (satoshiId, resurrectionType) => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return false
  }
  // console.log('transactom data for resurrect', [address.address, utilityTokenContractAddress, satoshiId])
  let contractMethod = ''
  if (resurrectionType === 'hell') {
    contractMethod = utilityTokenContractHTTP.methods.resurrectHellPublic(satoshiId).encodeABI()
  }

  if (resurrectionType === 'heaven') {
    contractMethod = utilityTokenContractHTTP.methods.resurrectHeavenPublic(satoshiId).encodeABI()
  }

  if (resurrectionType === 'self-heaven') {
    contractMethod = utilityTokenContractHTTP.methods.selfResurrectHeaven(satoshiId).encodeABI()
  }
  if (resurrectionType === 'self-hell') {
    contractMethod = utilityTokenContractHTTP.methods.selfResurrectHell(satoshiId).encodeABI()
  }

  const transactionParameters = {
    from: address.address,
    to: utilityTokenContractAddress,
    data: contractMethod
  }

  // Sign the transaction using Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    // Ako je transakcija uspela, smanji broj wl mesta u bazi, ako nje, resetuj
    const txResultStatus = await provider.waitForTransaction(txHash).then((result) => {
      return result
    })
    if (txResultStatus.status === 1) {
      // console.log('Satoshi resurrect', txHash)
    }
    return true
  } catch (error) {
    // console.log('Satoshi resurrect failed')
    return false
  }
}
export const addSatoshiToHell = async (satoshiId) => {
  const address = await getCurrentWalletAddress()
  if (address.address === '') {
    return false
  }

  const transactionParameters = {
    from: address.address,
    to: utilityTokenContractAddress,
    data: utilityTokenContractHTTP.methods.addSatoshiToHell(satoshiId).encodeABI()
  }

  // Sign the transaction using Metamask
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters]
    })
    // Ako je transakcija uspela, smanji broj wl mesta u bazi, ako nje, resetuj
    const txResultStatus = await provider.waitForTransaction(txHash).then((result) => {
      return result
    })
    if (txResultStatus.status === 1) {
      // console.log('Satoshi added to hell', txHash)
    }
    return true
  } catch (error) {
    // console.log('Add satoshi to hell failed')
    return false
  }
}
export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      const obj = {
        address: addressArray[0],
        status: 'Wallet connected'
      }
      return obj
    } catch (err) {
      return {
        address: '',
        status: 'Something went wrong...'
      }
    }
  } else {
    return {
      address: '',
      status: ('')
    }
  }
}
export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts'
      })

      if (addressArray.length > 0) {
        return {
          address: addressArray[0]
        }
      } else {
        return {
          address: '',
          status: '🦊 Connect using Metamask.'
        }
      }
    } catch (err) {
      return {
        address: '',
        status: 'Error :( ' + err.message
      }
    }
  } else {
    return {
      address: '',
      status: ('')
    }
  }
}

export const getCurrentWalletAddress = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts'
      })

      if (addressArray.length > 0) {
        return {
          address: addressArray[0]
        }
      } else {
        return {
          address: '',
          status: '🦊 Connect using Metamask.'
        }
      }
    } catch (err) {
      return {
        address: '',
        status: 'Error :( ' + err.message
      }
    }
  } else {
    return {
      address: '',
      status: ('')
    }
  }
}
