import React from 'react'
import styled from 'styled-components'
import { RedText } from '../redText/RedText'
import { Text } from '../text/Text'
import { withTheme } from '../../theme'
import { useDevice } from '../../context/device/DeviceContextProvider'

const FamilyStyle = styled.section`
  padding-top: 125px;
  background-color: #001523;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Description = styled.div`
  width: ${props => props.isMobile ? '90vw' : '45vw'};
  text-align: center;
`

const Paragraph = styled.div`
  text-align: center;
  width: ${props => props.isMobile ? '80vw' : '100%'};
  margin-left: ${props => props.isMobile ? 'auto' : ''};
  margin-right: ${props => props.isMobile ? 'auto' : ''};
`

const FamilyPic = styled.div`
  background-image: url("/img/satoshis/Family.png");
  background-size: cover;
  background-position: center center;
  margin-top: ${_ => _.isMobile ? '90px' : '-90px'};
  width: 100vw;
  height: 55vw;
  // removed this because of safari, added height to fix issue
  // aspect-ratio: 1490 / 886;
  z-index: 2;
`

const BlackGround = withTheme(styled.div`
  background-color: ${_ => _.theme.backgroundColor};
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${_ => _.isMobile ? '25px' : '121px'};
  // removed this because of safari, added height to fix issue
  // aspect-ratio: 1490 / 150;
  z-index: 1;
`)

export const Family = () => {
  const { isMobile } = useDevice()
  return (
    <FamilyStyle>
      <Description isMobile={isMobile}>
        <RedText size={'3.125rem'} lineHeight={'2.5rem'} isBlack={true} isMobile={isMobile}>Family photos</RedText>
        <Paragraph isMobile={isMobile}>
          <Text textAlign={'center'}>
            Many of us made a lot of friends very quickly on our Discord server, and we would all want to have our group photos :) Invite your friends and combine your Satoshis into a family photo and mint it for free.
          </Text>
        </Paragraph>
      </Description>
      <BlackGround isMobile={isMobile}/>
      <FamilyPic isMobile={isMobile}/>
    </FamilyStyle>
  )
}
