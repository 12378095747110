import React from 'react'
import styled from 'styled-components'
import { useDevice } from '../../context/device/DeviceContextProvider'

const WeAreSatoshisStyle = styled.div`
  width: 90vw;
  height: auto;
`

// Mobile
const WeAreSatoshisStyleMobile = styled.div`
  width: 100vw;
  height: auto;
`

export const WeAreSatoshis = () => {
  const { isDesktop } = useDevice()

  return (
    <>
    { isDesktop
      ? (
        <WeAreSatoshisStyle>
          <img style={{ width: '100%' }} src={'/img/sprites/WeAreSatoshis.gif'}/>
        </WeAreSatoshisStyle>
        )
      : (
        <WeAreSatoshisStyleMobile>
          <img style={{ width: '100%' }} src={'/img/sprites/WeAreSatoshis-Mobile.gif'}/>
        </WeAreSatoshisStyleMobile>
        )}
    </>
  )
}
