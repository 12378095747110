import React from 'react'
import styled from 'styled-components'
import { VideoEmbed } from '../videoEmbed/VideoEmbed'
import { useDevice } from '../../context/device/DeviceContextProvider'

const IntroVideoStyle = styled.div`
  margin: auto;
  margin-block: 40px;
`

export const IntroVideo = () => {
  const { isMobile } = useDevice()
  return (
    <IntroVideoStyle>
      <VideoEmbed
        width={isMobile ? '85vw' : '90vw'}
        src={'https://www.youtube.com/embed/y6sV7PnHvos'}/>
    </IntroVideoStyle>
  )
}
