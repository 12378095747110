import React from 'react'
import styled from 'styled-components'
import { BlackText } from '../blackText/BlackText'
import { RedText } from '../redText/RedText'
import { Text, TextInline } from '../text/Text'
import { useDevice } from '../../context/device/DeviceContextProvider'

const ResurrectionStyle = styled.section`
  background-color: #E4E0E0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 132px;
  padding-bottom: ${props => props.isMobile ? '0px' : '147px'};
  margin-top: 130px;
  width: 100vw;
`

const Header = styled.div`
  text-align: center;
  width: ${props => props.isMobile && '80vw'};
  margin-bottom: ${props => props.isMobile && '10vw'};
`

const Paragraph = styled.div`
  text-align: center;
  width: ${props => props.isMobile ? '80vw' : '49%'};
`

export const Completed = () => {
  const { isMobile } = useDevice()
  return (
    <ResurrectionStyle isMobile={isMobile}>
      <Header isMobile={isMobile}>
        <div><BlackText fontSize={isMobile ? '50px' : ''} lineHeight={isMobile ? '50px' : ''}>Roadmap</BlackText></div>
        <div>
          <RedText isBlack={true} size={isMobile ? '50px' : '8.125rem'} lineHeight={isMobile ? '50px' : '7.5rem'}>Completed!</RedText>
        </div>
      </Header>
      <Paragraph isMobile={isMobile}>
        <Text textAlign={'center'} fontSize={isMobile ? '18px' : ''} isBold={true} lineHeight={isMobile ? '22px' : ''}>
          On December 30th 2023. We Are Satoshis officially completed the entire roadmap. You can read more about it the official tweet <a href={'https://twitter.com/WeAreSatoshis/status/1741120379223957538'} target='_blank' rel="noreferrer"><TextInline color={'#FF1F1F'}>here.</TextInline></a>
        </Text>
      </Paragraph>
    </ResurrectionStyle>
  )
}
