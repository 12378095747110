import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDevice } from '../../context/device/DeviceContextProvider'

export const TextStyle = styled.p`
  font-size: ${_ => (_.isMobile && '18px') || _.fontSize || '1.25rem'};
  line-height: ${_ => (_.isMobile && '22px') || _.lineHeight || '1.5625rem'};
  text-align: ${_ => (_.isMobile && 'center') || _.textAlign || 'left'};
  font-weight: ${_ => _.fontWeight ?? (_.isMobile ? 700 : 700)};
  font-family: ${_ => (_.isBlack && 'Satoshi-Black') || (_.isBold && 'Satoshi-Bold')};
  margin-top: 40px;
  `

export const Text = ({ children, ...rest }) => {
  const { isMobile } = useDevice()
  return (
    <TextStyle {...rest} isMobile={isMobile}>
      {children}
    </TextStyle>
  )
}

Text.propTypes = {
  children: PropTypes.node,
  isBlack: PropTypes.bool,
  isBold: PropTypes.bool,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  textAlign: PropTypes.string,
  fontWeight: PropTypes.number,
  width: PropTypes.string
}

export const TextInline = styled.span`
  font-size: ${_ => _.fontSize || '1.25rem'};
  line-height: ${_ => _.lineHeight || '1.5625rem'};
  color: ${_ => _.color || 'inherit'};
  font-family: 'Satoshi-Bold';
  text-align: ${_ => _.textAlign};
  margin-top: ${_ => _.marginTop};
  margin-left: ${_ => _.marginLeft};
  margin-bottom: ${_ => _.marginBottom};
  cursor: ${_ => _.cursor};
  display: ${_ => _.display};
  & > a {
    color: ${_ => _.color || 'inherit'};
  }
  `
