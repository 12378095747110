import React, { useContext, useEffect, useState } from 'react'
import { throttle } from 'lodash/function'
import PropTypes from 'prop-types'

const DEFAULT_CONTEXT = { isMobile: false, isDesktop: true }

const DEVICE_BREAKPOINT = 767

export const DeviceContext = React.createContext(DEFAULT_CONTEXT)

export const DeviceContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isDesktop, setIsDesktop] = useState(true)

  useEffect(() => {
    const setDevice = () => {
      const isMobile = window.outerWidth <= DEVICE_BREAKPOINT
      setIsMobile(isMobile)
      setIsDesktop(!isMobile)
    }
    setDevice() // To initiate
    const setDeviceThrottled = throttle(setDevice, 1000)
    window.addEventListener('resize', setDeviceThrottled)
    return () => window.removeEventListener('resize', setDeviceThrottled)
  }, [])

  return (
    <DeviceContext.Provider value={{ isMobile, isDesktop }}>
      {children}
    </DeviceContext.Provider>
  )
}

DeviceContextProvider.propTypes = {
  children: PropTypes.node
}

export const useDevice = () => useContext(DeviceContext)
