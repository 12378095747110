import React from 'react'
import { WeAreSatoshis } from '../../components/weAreSatoshis/WeAreSatoshis'
import { IntroVideo } from '../../components/introVideo/IntroVideo'
import { WeBelieve } from '../../components/weBelieve/WeBelieve'
import { WhyHowWhatSection } from '../../components/whyHowWhatSection/WhyHowWhatSection'
import { Resurrection } from '../../components/resurrection/Resurrection'
import { Family } from '../../components/family/Family'
import { ForOwners } from '../../components/forOwners/ForOwners'
import { Artwork } from '../../components/artwork/Artwork'
import { SummaryAndTimeline } from '../../components/summaryAndTimeline/SummaryAndTimeline'
import { Team } from '../../components/team/Team'
import { JoinTheMovement } from '../../components/joinTheMovement/JoinTheMovement'
import { Completed } from '../../components/completed/Completed'

export const HomePage = () => {
  return (
    <>
      <WeAreSatoshis/>
      <IntroVideo/>
      <WeBelieve/>
      <WhyHowWhatSection/>
      <Resurrection/>
      <Family/>
      <ForOwners/>
      <Artwork/>
      <SummaryAndTimeline/>
      <Team/>
      <Completed/>
      <JoinTheMovement/>
    </>
  )
}
